//libraries
import * as React from "react";
import { DownloadIcon } from "@heroicons/react/outline";
//containers
import Container from "../../containers/container";
import PackageCard from "./package-card";

const packages = [
  {
    name: "Junior",
    price: 500,
    description: "Juniors must be less than 18 years old as at 1st January",
  },
  {
    name: "Adult",
    price: 1000,
    description:
      "Save every year compared to the monthly plan by paying yearly.",
  },
];

const Packages: React.FC = () => (
  <Container>
    <div className="mx-auto py-8 sm:py-12 text-center">
      <span className="text-blue-500 hover:text-green">
        <a
          href="downloads/membershipform.pdf"
          target="_blank"
          className="leading-6"
        >
          <DownloadIcon className="h-6 w-6 inline mr-2" />
          DOWNLOAD MEMBERSHIP FORM
        </a>
      </span>
    </div>
    <div className="my-0 py-6 sm:pb-16 bg-light">
      <p className="text-center font-semibold text-4xl sm:text-5xl md:text-6xl text-blue-500 py-8 sm:py-12">
        Choose your Package
      </p>
      <div className="w-6 h-1 mb-4 bg-green mx-auto"></div>
      <div className="uppercase mb-20 sm:mb-24 md:mb-28 max-w-sm sm:max-w-2xl mx-auto">
        <p className="text-xs sm:text-sm md:text-base text-center text-blue-400 m-auto font-thin leading-6">
          Tennis Club is honored to work with the country’s league best tennis
          players that now can become your personal coach and help you win
        </p>
      </div>
      <div className="mx-auto max-w-sm sm:max-w-3xl mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {packages.map((pack) => (
            <PackageCard key={pack.name} {...pack} />
          ))}
        </div>
      </div>
    </div>
    <div className="py-6 sm:py-8 mb-8 sm:mb-14">
      <p className="text-center">
        Contact the Club&#8217;s Membership Director for more information at{" "}
        <a className="text-green" href="mailto:tennissey@gmail.com">
          tennissey@gmail.com
        </a>
      </p>
    </div>
  </Container>
);
export default Packages;
