import * as React from "react";

interface PackageCardProps {
  name: string;
  price: number;
  description: string;
}

const PackageCard: React.FC<PackageCardProps> = ({
  name,
  price,
  description,
}) => (
  <div className="mb-6 sm:mb-0 transform hover:-translate-y-3 transition ease-in duration-300">
    <div className="bg-white text-blue-500 text-center pb-8">
      <div className="bg-blue-500 text-white font-medium text-2xl text-center p-4 sm:p-6">
        <span>{name}</span>
      </div>
      <div className="my-8">
        <div>
          <span className="text-4xl font-bold">{price}</span>
          <span className="font-semibold text-2xl ml-1">SCR</span>
          <span className="ml-2 font-normal text-xl">/year</span>
        </div>
      </div>
      <div className="m-4 sm:m-6 md:m-8 h-24 overflow-hidden text-light">
        {description}
      </div>
      <a href="downloads/membershipform.pdf" target="_blank">
        <div className="w-2/3 mx-auto bg-blue-400 hover:bg-blue-420 text-white px-4 py-6 sm:py-4">
          <span className="px-4 py-4">Order now</span>
        </div>
      </a>
    </div>
  </div>
);
export default PackageCard;
