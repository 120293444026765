//libraries
import * as React from "react";
//containers
import Layout from "../containers/layout";
//components
import Seo from "../components/seo";
import Packages from "../components/membership/packages";

const Membership: React.FC = () => (
  <Layout>
    <Seo
      title="Membership"
      description="become a member in seychelles tennis association"
    />
    <Packages />
  </Layout>
);
export default Membership;
